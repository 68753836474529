<template>
  <b-container>
    <b-row class="mb-5 pt-5 d-flex justify-content-between text-start">
      <b-col><curva-title :title="$t('main.arrivals')"></curva-title></b-col>
      <!--      <dropdown-btn @select="getSelected" />-->
    </b-row>
    <b-row id="offers-list" class="mb-5" v-if="arrivals.length > 0">
      <b-col
          lg="3"
          md="6"
          sm="6"
          v-for="(product, key) in arrivals"
          :key="key"
      >
        <product-card :product="product" />
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import HomeServices from '@/modules/index/services/index.services'
import { core } from '@/config/pluginInit'

export default {
  data () {
    return {
      arrivals: [],
      sortValue: 'id&dir=desc',
      perPage: 0,
      currentPage: 1,
      rows: 1
    }
  },
  methods: {
    getHomeData () {
      HomeServices.getHomeData().then(res => {
        this.arrivals = res.data.data.arrivals
      })
    }
  },
  created () {
    this.getHomeData()
  },
  mounted () {
    core.index()
  }
}
</script>
